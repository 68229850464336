/** ========================================================= * NextJS Material Dashboard 2 PRO - v2.0.0  ========================================================= * Product Page: https://www.creative-tim.com/product/nextjs-material-dashboard-pro  * Copyright 2022 Creative Tim (https://www.creative-tim.com)  Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */

// react
import { useMaterialUIController } from "/context";

// next
import dynamic from "next/dynamic";
import Link from "next/link";

// prop-types
import PropTypes from "prop-types";

// MUI
const Collapse = dynamic(() => import("@mui/material/Collapse"), { loading: () => null });

// MUI Icons
const KeyboardArrowDownRounded = dynamic(() => import('@mui/icons-material/KeyboardArrowDownRounded'), { loading: () => null });

// PRO
const MDBox = dynamic(() => import('/components/MDBox'), { loading: () => null });
const MDTypography = dynamic(() => import('/components/MDTypography'), { loading: () => null });

function DefaultNavbarDropdown({
  name,
  icon,
  children,
  collapseStatus,
  light,
  href,
  route,
  collapse,
  ...rest
}) {
  const [controller] = useMaterialUIController();

  return (
    <>
      <MDBox
        {...rest}
        color={light ? "white" : "dark"}
        opacity={light ? 1 : 1}
        sx={{ cursor: "pointer", userSelect: "none" }}
        component={Link}
        href={route}
      >
        <span>
          <MDBox display="flex" alignItems="baseline" mx={2} py={0.5}>
            {icon && (
              <MDTypography
                variant="body2"
                lineHeight={1}
                color={controller.darkMode ? "white" : "white"}
                sx={{ alignSelf: "center", "& *": { verticalAlign: "middle" } }}
              >
                {icon}
              </MDTypography>
            )}
            <MDTypography variant="button" fontWeight="regular" textTransform="capitalize" color={light ? "white" : "dark"} sx={{ fontWeight: "100%", ml: 1, mr: 0.25 }}
            >
              {name}
            </MDTypography>
            {collapse && (
              <MDTypography variant="body2" color={light ? "white" : "dark"} ml="auto" sx={{ lineHeight: 0 }}>
                <KeyboardArrowDownRounded sx={{ fontWeight: "normal", verticalAlign: "middle" }} />
              </MDTypography>
            )}
          </MDBox>
        </span>
      </MDBox>
      {children && (
        <Collapse in={Boolean(collapseStatus)} timeout={400} unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

// Setting default values for the props of DefaultNavbarDropdown
DefaultNavbarDropdown.defaultProps = {
  icon: false,
  children: false,
  collapseStatus: false,
  light: false,
  href: "",
  route: "",
};

// Typechecking props for the DefaultNavbarDropdown
DefaultNavbarDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.node,
  children: PropTypes.node,
  collapseStatus: PropTypes.bool,
  light: PropTypes.bool,
  href: PropTypes.string,
  route: PropTypes.string,
  collapse: PropTypes.bool.isRequired,
};

export default DefaultNavbarDropdown;
